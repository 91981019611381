import React, { useContext, useEffect, useState } from "react";

import ContentfulContext from "../ContentfulContext";
import Pageproduit from "./Pageproduit.js";
import NotFound from "./NotFound";

function PageProduitQuery(props) {
  const [currentShopItemID, setCurrentShopItemID] = useState();
  const [suggestedItemsTab, setSuggestedItemsTab] = useState();
  const [isProductComingSoon, setIsProductComingSoon] = useState(false);
  const client = useContext(ContentfulContext);

  console.log(
    "props.match.params.produit_url : ",
    props.match.params.produit_url
  );

  client
    .getEntries({
      content_type: "pageBoutiqueItem",
      "fields.url": props.match.params.produit_url,
    })
    .then((response) => {
      console.log(
        "response.items[0].fields.bigwaxId : ",
        response.items[0].fields.bigwaxId
      );
      setCurrentShopItemID(response.items[0].fields.bigwaxId);
      setIsProductComingSoon(response.items[0].fields.comingSoon);
    })
    .catch(console.error);

  console.log("props.dataShop : ", props.dataShop);

  useEffect(() => {
    function getRandomInt(min, max, previousNb) {
      min = Math.ceil(min);
      max = Math.floor(max);
      var res = Math.floor(Math.random() * (max - min + 1)) + min;
      if (previousNb.includes(res)) {
        res = getRandomInt(min, max, previousNb);
      }
      return res;
    }
    if (props.dataShop.items) {
      console.log("props.dataShop.items : ", props.dataShop.items);
      let randomItemsTab = new Array(5);
      let randomNbTab = new Array(5);
      for (let i = 0; i < randomItemsTab.length; i++) {
        randomNbTab[i] = getRandomInt(
          0,
          props.dataShop.items.length - 1,
          randomNbTab
        );
        if (props.dataShop.items[randomNbTab[i]].fields.prix !== "RUPTURE") {
          randomItemsTab[i] = {
            item: props.dataShop.items[randomNbTab[i]],
          };
        }
      }
      setSuggestedItemsTab(randomItemsTab);
    }
  }, [props.dataShop]);

  // console.log("currentShopItemID : ", currentShopItemID);
  // console.log("suggestedItemsTab : ", suggestedItemsTab);

  return isProductComingSoon ? (
    <NotFound />
  ) : (
    <Pageproduit id={currentShopItemID} suggestedItemsTab={suggestedItemsTab} />
  );
}

export default PageProduitQuery;
