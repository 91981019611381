import React, { Component } from "react";
import "../css/Lieu.sass";

class Lieu extends Component {
  render() {
    return (
      <a
        href={
          "https://www.google.fr/maps/search/" +
          this.props.nom +
          " " +
          this.props.adresse
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="lieu-container-inline-block">
          <div className={"lieu-container " + this.props.couleur}>
            <br />
            <span className="lieu-titre">{this.props.nom}</span>
            <span className="lieu-adresse">{this.props.adresse}</span>
            <span className="lieu-codepostal">{this.props.codepostal}</span>
            <span className="lieu-pays">{this.props.pays}</span>
          </div>
        </div>
      </a>
    );
  }
}

export default Lieu;
