import React from "react";
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import "../css/Poseyradio.sass";
import ReactPlayer from "react-player";

// import music from "../radio/1 - Allah Las - Raspberry Jam.mp3";

class Poseyradio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      radioStarts: false,
      radioPlaying: false,
      musique: this.changeSrc(),
      titre: "",
    };
  }

  importAll = (r) => {
    return r.keys().map(r);
  };

  componentWillMount() {
    this.changeSrc();
  }

  changeSrc() {
    var random = Math.floor(Math.random() * 100);

    const musiques = this.importAll(
      require.context("../radio/", false, /\.(mp3)$/)
    );

    var titre = JSON.stringify(musiques[random]);
    titre = titre.substring(19, titre.length - 14);

    this.setState({
      musique: musiques[random],
      titre: titre,
    });

    console.log("STRING : " + titre);
  }

  handleToggleRadio = () => {
    this.setState({ radioPlaying: !this.state.radioPlaying });
    this.setState({ radioStarts: true });
    // this.changeSrc();
  };

  render() {
    return (
      <div className="radio-page">
        <ReactPlayer
          style={{ display: "none" }}
          playing={this.state.radioStarts}
          volume={1}
          muted={!this.state.radioPlaying}
          // loop={true}
          url={this.state.musique}
          onEnded={() => this.changeSrc()}
        />
        <Navbar currentPage="poseyradio" />
        <div>
          <header className="radio-header">
            <h3 className="drop-title">
              À ECOUTE<span className="redtext">Y</span> TRANQUILLEMEN
              <span className="redtext">T</span>
            </h3>
            <div className="radio-player-section">
              <button
                class={
                  this.state.radioPlaying ? "button playing" : "button paused"
                }
                onClick={this.handleToggleRadio}
              ></button>
              <div className="scrollingtext">
                <span>
                  <h5>{this.state.titre}</h5>
                </span>
              </div>
            </div>

            <div className="radio-soundcloud-section">
              {/* SUPERPOZE */}
              <iframe
                className="soundcloudplayer"
                title="soundcloud1"
                width="100%"
                height="300"
                scrolling="no"
                frameborder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/332485013&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
              ></iframe>
              <div
                style={{
                  fontSize: "10px",
                  color: "#cccccc",
                  lineBreak: "anywhere",
                  wordBreak: "normal",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  fontFamily:
                    "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
                  fontWeight: "100",
                }}
              >
                <a
                  href="https://soundcloud.com/poseypapi"
                  title="POSEY"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#cccccc", textDecoration: "none" }}
                >
                  POSEY
                </a>{" "}
                <a
                  href="https://soundcloud.com/poseypapi/selection-superpoze-calvi"
                  title="Posey #1 - Superpoze (feat Calvi On The Rocks)"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#cccccc", textDecoration: "none" }}
                >
                  Posey #1 - Superpoze (feat Calvi On The Rocks)
                </a>
              </div>

              {/* JEAN TONIQUE */}
              <iframe
                className="soundcloudplayer"
                title="soundcloud2"
                width="100%"
                height="300"
                scrolling="no"
                frameborder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/334013445&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
              ></iframe>
              <div
                style={{
                  fontSize: "10px",
                  color: "#cccccc",
                  lineBreak: "anywhere",
                  wordBreak: "normal",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  fontFamily:
                    "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
                  fontWeight: "100",
                }}
              >
                <a
                  href="https://soundcloud.com/poseypapi"
                  title="POSEY"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#cccccc", textDecoration: "none" }}
                >
                  POSEY
                </a>{" "}
                <a
                  href="https://soundcloud.com/poseypapi/posey-2-jean-tonique"
                  title="Posey #2 - Jean Tonique"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#cccccc", textDecoration: "none" }}
                >
                  Posey #2 - Jean Tonique
                </a>
              </div>

              {/* PAIN SURPRISES */}
              <iframe
                className="soundcloudplayer"
                title="soundcloud3"
                width="100%"
                height="300"
                scrolling="no"
                frameborder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/448584999&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
              ></iframe>

              <div
                style={{
                  fontSize: "10px",
                  color: "#cccccc",
                  lineBreak: "anywhere",
                  wordBreak: "normal",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  fontFamily:
                    "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
                  fontWeight: "100",
                }}
              >
                <a
                  href="https://soundcloud.com/poseypapi"
                  title="POSEY"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#cccccc", textDecoration: "none" }}
                >
                  POSEY
                </a>{" "}
                <a
                  href="https://soundcloud.com/poseypapi/posey-3-pain-surprises"
                  title="Posey #3 - Pain Suprises"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#cccccc", textDecoration: "none" }}
                >
                  Posey #3 - Pain Suprises
                </a>
              </div>
            </div>
          </header>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Poseyradio;
