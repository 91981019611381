import "../css/Footer.sass";

import { Link } from "react-router-dom";
import React from "react";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-item">
        <Link to="/mentions-legales" className="footer-link">
          Mentions légales et conditions générales de vente
        </Link>
      </div>
      <div className="footer-item">
        POSEY © {new Date().getFullYear()} - Tous droits réservés.
      </div>
      <div className="footer-item">
        L'abus d'alcool est dangereux pour la santé.
      </div>
    </div>
  );
}

export default Footer;
