import "./index.css";
import "./fonts/FuturaLTBook.ttf";
import "./fonts/AquaGrotesque.ttf";

import * as serviceWorker from "./serviceWorker";

import App from "./App.js";
import ContentfulContext from "./ContentfulContext.js";
import React from "react";
import ReactDOM from "react-dom";
import { createClient } from "contentful";

const client = createClient({
  space: "tdelhr9n7hbu",
  accessToken: "BVdlQBcAzWXH9Gz27eLhA6OEW6bHNDgLnpGGiOrFAg8",
});

ReactDOM.render(
  <React.StrictMode>
    <ContentfulContext.Provider value={client}>
      <App />
    </ContentfulContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
