import "../css/Pageproduit.sass";
import "rodal/lib/rodal.css";

import { BrowserView, MobileView } from "react-device-detect";
import React, { Component } from "react";

import { FaArrowAltCircleLeft } from "react-icons/fa";
import Footer from "../components/Footer.js";
import { Link } from "react-router-dom";
// import { useParams } from "react-router-dom";
import Navbar from "../components/Navbar.js";
import PopupLivraison from "../components/PopupLivraison.js";
import Produit from "../components/Produit.js";
import Slider from "react-slick";

class Pageproduit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsletterPopupVisible: false,
    };
  }

  show() {
    this.setState({ newsletterPopupVisible: true });
  }

  hide() {
    this.setState({ newsletterPopupVisible: false });
  }

  render() {
    let id = this.props.id;

    const settingsDesktop = {
      dots: false,
      infinite: true,
      speed: 1500,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 3,
      slidesToScroll: 1,
      swipe: false,
      pauseOnHover: false,
    };
    const settingsMobile = {
      dots: false,
      infinite: true,
      speed: 1500,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 2,
      slidesToScroll: 1,
      pauseOnHover: false,
    };

    const listRecommandedItems = this.props.suggestedItemsTab?.map(
      (randomItem, i) => {
        console.log("randomItem.fields : ", randomItem.item.fields);
        return (
          <span className="boutique-item" key={i}>
            <Produit
              produit_image={randomItem.item.fields.image.fields.file.url}
              produit_titre={randomItem.item.fields.titre}
              produit_prix={randomItem.item.fields.prix}
              produit_details={randomItem.item.fields.details}
              produit_id={randomItem.item.fields.bigwaxId}
              produit_url={randomItem.item.fields.url}
            />
          </span>
        );
      }
    );

    return (
      <div className="pageproduit-page">
        <Navbar currentPage="produit" />
        <PopupLivraison
          visible={this.state.newsletterPopupVisible}
          hide={() => this.hide()}
        />
        <header className="pageproduit-header">
          {/* BOUTON RETOUR ET LIVRAISON FRANCE */}
          <div className="pageproduit-menu">
            <Link to="/shop">
              <div className="pageproduit-retour">
                <div className="pageproduit-boutonRetour">
                  <FaArrowAltCircleLeft />
                </div>
              </div>
            </Link>
            <button
              onClick={this.show.bind(this)}
              className="pageproduit-livraison"
            >
              <span>LIVRAISON PARTOUT EN FRANCE</span>
            </button>
          </div>
          {/* IFRAME BIGWAX */}
          <div id="product-component"></div>
          {(function () {
            var scriptURL =
              "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";
            if (window.ShopifyBuy) {
              if (window.ShopifyBuy.UI) {
                ShopifyBuyInit();
              } else {
                loadScript();
              }
            } else {
              loadScript();
            }
            function loadScript() {
              var script = document.createElement("script");
              script.async = true;
              script.src = scriptURL;
              (
                document.getElementsByTagName("head")[0] ||
                document.getElementsByTagName("body")[0]
              ).appendChild(script);

              script.onload = ShopifyBuyInit;
            }
            function ShopifyBuyInit() {
              var client = window.ShopifyBuy.buildClient({
                domain: "poseypourtoujours.myshopify.com",
                storefrontAccessToken: "2f3a8b8da06d9d3b20b84ddc07b6371b",
              });
              window.ShopifyBuy.UI.onReady(client).then(function (ui) {
                ui.createComponent("product", {
                  id: id,
                  node: document.getElementById("product-component"),
                  moneyFormat:
                    "%E2%82%AC%7B%7Bamount_with_comma_separator%7D%7D",
                  options: {
                    product: {
                      iframe: false,
                      styles: {
                        product: {
                          "font-family": "Nunito",
                          "@media (min-width: 601px)": {
                            "max-width": "100%",
                            "margin-left": "0",
                            "margin-bottom": "50px",
                          },
                          "text-align": "left",
                        },
                        title: {
                          "font-family": "Nunito",
                          "font-weight": "normal",
                          "font-size": "26px",
                          color: "#000000",
                        },
                        button: {
                          "font-family": "Nunito",
                          "font-weight": "bold",
                          "font-size": "13px",
                          "padding-top": "14.5px",
                          "padding-bottom": "14.5px",
                          ":hover": {
                            "background-color": "#0092C8",
                          },
                          "background-color": "#0092C8",
                          ":focus": {
                            "background-color": "#0092C8",
                          },
                          "border-radius": "40px",
                          "padding-left": "37px",
                          "padding-right": "37px",
                        },
                        quantityInput: {
                          "font-family": "Nunito",
                          "font-size": "13px",
                          "padding-top": "14.5px",
                          "padding-bottom": "14.5px",
                        },
                        price: {
                          "font-family": "Nunito",
                          "font-size": "18px",
                          color: "#000000",
                        },
                        compareAt: {
                          "font-family": "Nunito",
                          "font-size": "15.299999999999999px",
                          color: "#000000",
                        },
                        unitPrice: {
                          "font-family": "Nunito",
                          "font-size": "15.299999999999999px",
                          color: "#000000",
                        },
                        description: {
                          color: "#000000",
                        },
                      },
                      layout: "horizontal",
                      contents: {
                        img: false,
                        imgWithCarousel: true,
                        description: true,
                      },
                      width: "100%",
                      text: {
                        button: "Commandey",
                      },
                    },
                    productSet: {
                      styles: {
                        products: {
                          "@media (min-width: 601px)": {
                            "margin-left": "-20px",
                          },
                        },
                      },
                    },
                    modalProduct: {
                      contents: {
                        img: false,
                        imgWithCarousel: true,
                      },
                      styles: {
                        product: {
                          "@media (min-width: 601px)": {
                            "max-width": "100%",
                            "margin-left": "0px",
                            "margin-bottom": "0px",
                          },
                        },
                        button: {
                          "font-family": "Nunito",
                          "font-weight": "bold",
                          "font-size": "13px",
                          "padding-top": "14.5px",
                          "padding-bottom": "14.5px",
                          ":hover": {
                            "background-color": "#0092C8",
                          },
                          "background-color": "#0092C8",
                          ":focus": {
                            "background-color": "#0092C8",
                          },
                          "border-radius": "40px",
                          "padding-left": "37px",
                          "padding-right": "37px",
                        },
                        quantityInput: {
                          "font-family": "Nunito",
                          "font-size": "13px",
                          "padding-top": "14.5px",
                          "padding-bottom": "14.5px",
                        },
                        title: {
                          "font-family": "Nunito",
                          "font-size": "28px",
                          color: "#ffffff",
                        },
                        price: {
                          "font-family": "Nunito",
                          "font-size": "14px",
                          color: "#ffffff",
                        },
                        compareAt: {
                          "font-family": "Nunito",
                          "font-size": "11.9px",
                          color: "#ffffff",
                        },
                        unitPrice: {
                          "font-family": "Nunito",
                          "font-size": "11.9px",
                          color: "#ffffff",
                        },
                        description: {
                          color: "#ffffff",
                        },
                      },
                      text: {
                        button: "Ajouter au panier",
                      },
                    },
                    modal: {
                      styles: {
                        modal: {
                          "background-color": "#000000",
                        },
                      },
                    },
                    option: {
                      styles: {
                        label: {
                          color: "#000000",
                        },
                      },
                    },
                    cart: {
                      iframe: true,
                      popup: false,
                      styles: {
                        button: {
                          "font-family": "Nunito",
                          "font-weight": "bold",
                          "font-size": "13px",
                          "padding-top": "14.5px",
                          "padding-bottom": "14.5px",
                          "background-color": "#FF93B9",
                          ":hover": {
                            "background-color": "#ff76a6",
                          },
                          ":focus": {
                            "background-color": "#ff76a6",
                          },
                          "border-radius": "40px",
                        },
                        title: {
                          color: "#000000",
                        },
                        header: {
                          color: "#000000",
                        },
                        lineItems: {
                          color: "#000000",
                        },
                        subtotalText: {
                          color: "#000000",
                        },
                        subtotal: {
                          color: "#000000",
                        },
                        notice: {
                          color: "#000000",
                        },
                        currency: {
                          color: "#000000",
                        },
                        close: {
                          color: "#000000",
                          ":hover": {
                            color: "#000000",
                          },
                        },
                        empty: {
                          color: "#000000",
                        },
                        noteDescription: {
                          color: "#000000",
                        },
                        discountText: {
                          color: "#000000",
                        },
                        discountIcon: {
                          fill: "#000000",
                        },
                        discountAmount: {
                          color: "#000000",
                        },
                      },
                      text: {
                        title: "PANIER",
                        notice: "",
                        total: "Total",
                        button: "PAIEMENT",
                        empty: "Aie, le panier est vide.",
                      },
                    },
                    toggle: {
                      styles: {
                        toggle: {
                          "font-family": "Nunito",
                          "font-weight": "bold",
                          "background-color": "#0092C8",
                          ":hover": {
                            "background-color": "#00698f",
                          },
                          ":focus": {
                            "background-color": "#00698f",
                          },
                        },
                        count: {
                          "font-family": "Nunito",
                          "font-size": "13px",
                        },
                      },
                    },
                    lineItem: {
                      styles: {
                        variantTitle: {
                          color: "#000000",
                        },
                        title: {
                          color: "#000000",
                        },
                        price: {
                          color: "#000000",
                        },
                        fullPrice: {
                          color: "#000000",
                        },
                        discount: {
                          color: "#000000",
                        },
                        discountIcon: {
                          fill: "#000000",
                        },
                        quantity: {
                          color: "#000000",
                        },
                        quantityIncrement: {
                          color: "#000000",
                          "border-color": "#000000",
                        },
                        quantityDecrement: {
                          color: "#000000",
                          "border-color": "#000000",
                        },
                        quantityInput: {
                          color: "#000000",
                          "border-color": "#000000",
                        },
                      },
                    },
                  },
                });
              });
            }
          })()}
        </header>
        {/* PEUT AUSSI VOUS INTÉRESSER */}
        <div className="shopSuggestionDiv">
          <div className="shopSuggestionsTitle">
            <span>On pose ça là...</span>
          </div>
          <BrowserView>
            <Slider {...settingsDesktop}>{listRecommandedItems}</Slider>
          </BrowserView>
          <MobileView>
            <Slider {...settingsMobile}>{listRecommandedItems}</Slider>
          </MobileView>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Pageproduit;
