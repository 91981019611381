import "../css/Boutique.sass";
import "rodal/lib/rodal.css";

import React, { Component } from "react";

import PopupLivraison from "../components/PopupLivraison";
import Produit from "../components/Produit.js";

class Boutique extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //     isAllOff: true,
      //     isRosé: false,
      //     isBlanc: false,
      //     isNature: false,
      //     isPack: false,
      //     isGoodies: false,
      popupVisible: false,
      //     // itemsArray: [],
    };
  }

  show() {
    this.setState({ popupVisible: true });
  }

  hide() {
    this.setState({ popupVisible: false });
  }

  // handleToggleRosé = () => {
  //   this.setState({ isRosé: !this.state.isRosé });
  //   this.setState({ isAllOff: false });
  // };
  // handleToggleBlanc = () => {
  //   this.setState({ isBlanc: !this.state.isBlanc });
  //   this.setState({ isAllOff: false });
  // };
  // handleToggleNature = () => {
  //   this.setState({ isNature: !this.state.isNature });
  //   this.setState({ isAllOff: false });
  // };
  // handleTogglePack = () => {
  //   this.setState({ isPack: !this.state.isPack });
  //   this.setState({ isAllOff: false });
  // };
  // handleToggleGoodies = () => {
  //   this.setState({ isGoodies: !this.state.isGoodies });
  //   this.setState({ isAllOff: false });
  // };
  // handleReset = () => {
  //   this.setState({
  //     isRosé: false,
  //     isBlanc: false,
  //     isNature: false,
  //     isPack: false,
  //     isGoodies: false,
  //   });
  // };

  // componentDidUpdate() {
  //   if (
  //     this.state.isRosé === false &&
  //     this.state.isBlanc === false &&
  //     this.state.isNature === false &&
  //     this.state.isPack === false &&
  //     this.state.isGoodies === false
  //   ) {
  //     if (this.state.isAllOff === false) {
  //       this.setState({ isAllOff: true });
  //     }
  //   }
  // }

  // componentDidMount() {
  // const itemsArray = this.props.data.items.map((itemShop, index) => {
  //   return (
  //     <span className="boutique-item">
  //       <Produit
  //         produit_image={itemShop.fields.image.fields.file.url}
  //         produit_titre={itemShop.fields.titre}
  //         produit_prix={itemShop.fields.prix}
  //         produit_id={itemShop.fields.bigwaxId}
  //       />
  //     </span>
  //   );
  // });
  // this.setState({ itemsArray: itemsArray });
  // }

  render() {
    let itemsArray = [];

    if (this.props.data?.items) {
      itemsArray = this.props.data.items.map((itemShop, index) => {
        return (
          <span className="boutique-item" key={index}>
            <Produit
              produit_image={itemShop.fields.image.fields.file.url}
              produit_titre={itemShop.fields.titre}
              produit_prix={
                itemShop.fields.comingSoon
                  ? "COMING SOON"
                  : itemShop.fields.prix
              }
              produit_details={itemShop.fields.details}
              produit_id={itemShop.fields.bigwaxId}
              produit_url={itemShop.fields.url}
              produit_comingSoon={itemShop.fields.comingSoon}
            />
          </span>
        );
      });
    }

    return (
      <div className="boutique-page">
        {/* <Rodal
          visible={this.state.newsletterPopupVisible}
          onClose={this.hide.bind(this)}
          className="apropos-news-rodal"
        >
          <div>
            <iframe
              title="NewsletterPopup"
              src="https://ec174375.sibforms.com/serve/MUIEAJ_S7FUGzaVDisK-6IH4VwHdqMKRi4AWuDcMN9Ai7kfCWHMAx4dhatnxAAx8h27RrBo-mbpAX9LUItocgaQBlyuRv3dpMqK5SpJAnlC-f7YQGn8BD6DYMTYeugsvXB2fKKdbAom_ghXNyWT7PC5733pgIYD3TRegC0GfNp0Xjv6pDPsxTMT3E4tPL_f8sSn9eLWA63l1OQDm"
              frameBorder="0"
              scrolling="auto"
              allowfullscreen
              className="apropos-news-iframe"
            ></iframe>
          </div>
        </Rodal> */}
        <PopupLivraison
          visible={this.state.popupVisible}
          hide={() => this.hide()}
        />
        <div className="boutique-button-container">
          <button
            onClick={this.show.bind(this)}
            className="boutique-newsletter"
          >
            <span>LIVRAISON PARTOUT EN FRANCE</span>
          </button>
        </div>
        <div className="boutique-content">
          {/* <div className="boutique-left-panel">
            <h5>Filtrer par...</h5>
            <div className="boutique-filters-container">
              <form>
                <div className="boutique-filter">
                  <input
                    onClick={this.handleToggleRosé}
                    checked={this.state.isRosé}
                    type="checkbox"
                    id="rosé"
                    name="rosé"
                    value="rosé"
                  />
                  <label for="rosé">Rosé</label>
                </div>
                <div className="boutique-filter">
                  <input
                    onClick={this.handleToggleBlanc}
                    checked={this.state.isBlanc}
                    type="checkbox"
                    id="blanc"
                    name="blanc"
                    value="blanc"
                  />
                  <label for="blanc">Blanc</label>
                </div>
                <div className="boutique-filter">
                  <input
                    onClick={this.handleToggleNature}
                    checked={this.state.isNature}
                    type="checkbox"
                    id="nature"
                    name="nature"
                    value="nature"
                  />
                  <label for="nature">Nature</label>
                </div>
                <div className="boutique-filter">
                  <input
                    onClick={this.handleTogglePack}
                    checked={this.state.isPack}
                    type="checkbox"
                    id="pack"
                    name="pack"
                    value="pack"
                  />
                  <label for="pack">Pack</label>
                </div>

                <input
                  onClick={this.handleToggleGoodies}
                  checked={this.state.isGoodies}
                  type="checkbox"
                  id="goodies"
                  name="goodies"
                  value="goodies"
                />
                <label for="goodies">Goodies</label>
                <br />
                <br />
                <div onClick={this.handleReset} className="boutique-button">
                  Effacer
                </div>
              </form>
            </div>
          </div> */}
          <div className="boutique-items-container">{itemsArray}</div>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Boutique;
