import "./App.css";

//Router
import { BrowserRouter, Route, Switch } from "react-router-dom";
//contentful
import { Helmet, HelmetProvider } from "react-helmet-async";
import React, { useContext, useEffect, useState } from "react";

//COMPONENT
import Accueil from "./pages/Accueil";
import Apropos from "./pages/Apropos";
import ContentfulContext from "./ContentfulContext";
// import BoutiqueQuery from "./pages/BoutiqueQuery";
import Drop from "./pages/Drop";
import DropContext from "./DropContext.js";
// import NotPublished from "./pages/NotPublished";
import Mentionslegales from "./pages/Mentionslegales";
import NotFound from "./pages/NotFound";
import Noustrouver from "./pages/Noustrouver";
import PageproduitQuery from "./pages/PageproduitQuery";
import Poseyradio from "./pages/Poseyradio";
import RadioContext from "./RadioContext.js";
import ScrollToTop from "./components/ScrollToTop";

// import Contact from "./pages/Contact";

const logo = "https://posey.cool/logobanner.jpg";

const setMetaTags = ({ title, description, url = "", imageUrl }) => (
  <HelmetProvider>
    <Helmet>
      <title>{title ? `${title}` : ""}</title>
      {/* Description : 150-160 caractères, unique par page */}
      <meta name="description" content={description} />

      <meta property="og:url" content={"https://posey.cool/" + url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:image" content="https://posey.cool/logobanner.jpg" />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="Posey" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@leroseposey" />
      <meta name="twitter:url" content={"https://posey.cool/" + url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={"https://posey.cool" + imageUrl} />
      <meta
        name="facebook-domain-verification"
        content="ogp36tgldo4ss25vq16rprxllshvo0"
      />
    </Helmet>
  </HelmetProvider>
);

const Routes = {
  // pages principales
  accueil: "/",
  pageproduit: "/produit/:produit_url",
  // shop: "/shop",
  drop: "/drop",
  poseyradio: "/posey-radio",
  noustrouver: "/nous-trouver",
  apropos: "/a-propos",
  mentionslegales: "/mentions-legales",

  // boutique
  // mataharitshirt: "/boutique/merch/matahari-t-shirt",
  // isaacdelusiontshirt: "/boutique/merch/isaac-t-shirt",
  // isaacdelusioncasquette: "/boutique/merch/isaac-casquette",
};

function Layout() {
  const [drop, setDrop] = useState(false);
  const [radio, setRadio] = useState(false);
  const [dataShop, setDataShop] = useState({});

  const client = useContext(ContentfulContext);

  // useEffect(() => {
  //   try {
  //     const detectBrowserLanguage = require("detect-browser-language");
  //     if (detectBrowserLanguage().includes("fr")) {
  //       setUserLanguage("fr");
  //     } else {
  //       setUserLanguage("en");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, []);

  useEffect(() => {
    client
      .getEntry("21FPwzN41eR3FJ4XgmvCnc")
      .then((entry) => {
        setDrop(entry.fields.drop);
        setRadio(entry.fields.radio);
      })
      .catch(console.error);

    //LOAD BOUTIQUE DATA
    client
      .getEntry("3dKLIU7PpReHkOlBtKNseE")
      .then((entry) => {
        setDataShop(entry.fields);
      })
      .catch(console.error);
  }, [client]);

  console.log({ dataShop });

  return (
    <div className="Layout" id="outer-container">
      <BrowserRouter>
        <RadioContext.Provider value={radio}>
          <DropContext.Provider value={drop}>
            <ScrollToTop />
            <div>
              <Switch>
                <Route
                  exact
                  path={Routes.poseyradio}
                  render={({ location }) => {
                    return (
                      <>
                        {setMetaTags({
                          title: "Radio - POSEY",
                          description:
                            "C’est rigolo, c’est beau et c’est bon. ",
                          url: location.pathname,
                          imageUrl: logo,
                        })}
                        {radio ? <Poseyradio /> : <NotFound />}
                      </>
                    );
                  }}
                />

                {/* <Route
                exact
                path={Routes.shop}
                render={({ location }) => {
                  return (
                    <>
                      {setMetaTags({
                        title: "Shop - POSEY",
                        description: "C’est rigolo, c’est beau et c’est bon. ",
                        url: location.pathname,
                        imageUrl: logo,
                      })}
                      <BoutiqueQuery />
                    </>
                  );
                }}
              /> */}
                <Route
                  exact
                  path={Routes.drop}
                  render={({ location }) => {
                    return (
                      <>
                        {setMetaTags({
                          title: "Drop - POSEY",
                          description:
                            "Grand vin de vancances mis en bouteille tranquillement. C’est rigolo, c’est beau et c’est bon.",
                          url: location.pathname,
                          imageUrl: logo,
                        })}
                        {drop ? <Drop /> : <NotFound />}
                      </>
                    );
                  }}
                />
                <Route
                  exact
                  path={Routes.apropos}
                  render={({ location }) => {
                    return (
                      <>
                        {setMetaTags({
                          title: "À propos - POSEY",
                          description:
                            "C’est rigolo, c’est beau et c’est bon. ",
                          url: location.pathname,
                          imageUrl: logo,
                        })}
                        <Apropos />
                      </>
                    );
                  }}
                />
                <Route
                  exact
                  path={Routes.mentionslegales}
                  render={({ location }) => {
                    return (
                      <>
                        {setMetaTags({
                          title: "Mentions légales - POSEY",
                          description:
                            "C’est rigolo, c’est beau et c’est bon. ",
                          url: location.pathname,
                          imageUrl: logo,
                        })}
                        <Mentionslegales />
                      </>
                    );
                  }}
                />
                <Route
                  exact
                  path={Routes.accueil}
                  render={({ location }) => {
                    return (
                      <>
                        {setMetaTags({
                          title: "ROSÉ POSEY",
                          description:
                            "C’est rigolo, c’est beau et c’est bon. ",
                          url: location.pathname,
                          imageUrl: logo,
                        })}
                        <Accueil dataShop={dataShop} />
                      </>
                    );
                  }}
                />
                <Route
                  path={Routes.pageproduit}
                  render={({ location, props, match }) => {
                    return (
                      <>
                        {setMetaTags({
                          title: "Boutique - POSEY",
                          description:
                            "C’est rigolo, c’est beau et c’est bon. ",
                          url: location.pathname,
                          imageUrl: logo,
                        })}
                        <PageproduitQuery
                          props={props}
                          match={match}
                          dataShop={dataShop}
                        />
                      </>
                    );
                  }}
                />
                <Route
                  exact
                  path={Routes.noustrouver}
                  render={({ location }) => {
                    return (
                      <>
                        {setMetaTags({
                          title: "Nous trouver - POSEY",
                          description:
                            "C’est rigolo, c’est beau et c’est bon. ",
                          url: location.pathname,
                          imageUrl: logo,
                        })}
                        <Noustrouver />
                      </>
                    );
                  }}
                />
                <Route
                  render={({ location }) => {
                    return (
                      <>
                        {setMetaTags({
                          title: "Erreur 404 –— ROSÉ POSEY",
                          description:
                            "C’est rigolo, c’est beau et c’est bon. ",
                          url: location.pathname,
                          imageUrl: logo,
                        })}
                        <NotFound />
                      </>
                    );
                  }}
                />
                {/*  BOUTON LANGUE
                <div>

                    <button onClick={() => i18n.changeLanguage('de')}>de</button>
                    <button onClick={() => i18n.changeLanguage('en')}>en</button>
                </div>
                */}
              </Switch>
            </div>
          </DropContext.Provider>
        </RadioContext.Provider>
      </BrowserRouter>
    </div>
  );
}

export default Layout;

// export default translate('trad')(Layout);
// instead of "export default App;"
