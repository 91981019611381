import React from "react";
import logo from "../img-js/logo-posey-bbr.png";
import "../css/NotFound.sass";
import { Link, Redirect } from "react-router-dom";

function NotFound() {
  return (
    <div>
      <div className="App">
        <header className="NotFound-page">
          <div className="notfound-background"></div>
          <span className="notfound-banner">
            <img className="notfound-logo" src={logo} alt="logo" />
            <div className="notfound-message">
              Oups, mauvaise page
              <br />
              <Link to="/">
                <p className="notfound-message">Accueil</p>
              </Link>
              <Redirect to="/" />
            </div>
          </span>
        </header>
      </div>
    </div>
  );
}

export default NotFound;
