import "../css/Accueil.sass";
import "rodal/lib/rodal.css";

import React, { useContext, useEffect, useState } from "react";

import Boutique from "./Boutique";
import ContentfulContext from "../ContentfulContext.js";
import Div100vh from "react-div-100vh";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import Rodal from "rodal";
import Slider from "react-slick";

function Accueil({ dataShop }) {
  const [popupAgeVisible, setPopupAgeVisible] = useState(false);
  const [imagesArray, setImagesArray] = useState([]);
  const [shopClicked, setShopClicked] = useState(false);
  const [mouseDownX, setMouseDownX] = useState(1);
  const [mouseDownY, setMouseDownY] = useState(2);
  const [mouseUpX, setMouseUpX] = useState(3);
  const [mouseUpY, setMouseUpY] = useState(4);

  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
  };
  const client = useContext(ContentfulContext);

  useEffect(() => {
    let params = new URLSearchParams(document.location.search);
    let shop = JSON.parse(params.get("shop")); // is the string "Jonathan"
    if (shop === 1) {
      setShopClicked(true);
      window.scrollTo(0, (90 * window.innerHeight) / 100);
    }
  }, []);

  useEffect(() => {
    if (mouseDownX === mouseUpX && mouseDownY === mouseUpY) {
      window.scrollTo(0, (90 * window.innerHeight) / 100);
    }
  }, [mouseDownX, mouseUpX, mouseDownY, mouseUpY]);

  useEffect(() => {
    //LOAD HOME DATA
    client
      .getEntry("21FPwzN41eR3FJ4XgmvCnc")
      .then((entry) => {
        const tempArray = entry.fields.slider.map((image, i) => {
          return (
            <div
              onMouseDown={(e) => {
                setMouseDownX(e.clientX);
                setMouseDownY(e.clientY);
              }}
              onMouseUp={(e) => {
                setMouseUpX(e.clientX);
                setMouseUpY(e.clientY);
              }}
              key={i}
            >
              <img src={image.fields.file.url} alt="" />
            </div>
          );
        });
        setImagesArray(tempArray);
      })
      .catch(console.error);

    let visited = localStorage["alreadyVisited"];
    if (visited) {
      //do not view Popup
      setPopupAgeVisible(false);
    } else {
      //this is the first time
      setPopupAgeVisible(true);
    }
  }, [client]);

  return (
    <div className="accueil">
      <Rodal
        visible={popupAgeVisible}
        onClose={() => setPopupAgeVisible(false)}
        className="accueil-rodal"
        closeMaskOnClick={false}
        showCloseButton={false}
        animation="fade"
      >
        <div className="accueil-rodal">
          <div className="accueil-rodal-titre">
            <h4>
              Être Posey c'est bien
              <br /> mais il faut être majeur
            </h4>
          </div>
          <button
            onClick={() => {
              setPopupAgeVisible(false);
              localStorage["alreadyVisited"] = true;
            }}
            className="accueil-rodal-button yes"
          >
            J'ai plus de 18 ans
          </button>
          <a href="https://fr.wikipedia.org/wiki/Champomy">
            <button className="accueil-rodal-button no">
              Je n'ai pas plus de 18 ans
            </button>
          </a>
        </div>
      </Rodal>

      <Navbar
        currentPage={shopClicked ? "boutique" : "home"}
        scroll={() => window.scrollTo(0, (90 * window.innerHeight) / 100)}
        enableShop={() => setShopClicked(true)}
        disableShop={() => setShopClicked(false)}
      />
      <Div100vh className="accueil-part">
        {/* NAVBAR */}
        {/* PHOTOS SLIDESHOW */}
        <div className="accueil-slideshow-container">
          <Slider {...settings}>{imagesArray}</Slider>
        </div>
      </Div100vh>
      <Boutique data={dataShop} />
      <Footer />
    </div>
  );
}

export default Accueil;
