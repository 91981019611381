import "../css/Mentionslegales.sass";

import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import React from "react";

function Mentionslegales() {
  return (
    <div className="">
      <Navbar currentPage="mentionslegales" />
      <div className="mentionslegales-header">
        <div className="mentionslegales-content-container">
          <div className="apropos-block">
            <h3 className="apropos-title">CONTACTS & CRÉDITS</h3>
            <span className="apropos-text">
              <br />
              Tranquillement SAS
              <br />
              Contact >{" "}
              <a
                href="mailto:poseypourtoujours@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                poseypourtoujours@gmail.com
              </a>
              <br />
              <br />
              Ce site web a été développé par{" "}
              <a
                href="https://pecorino.studio"
                target="_blank"
                rel="noopener noreferrer"
              >
                pecorino.studio
              </a>
              <br />
              Contact >{" "}
              <a
                href="mailto:romain@pecorino.studio"
                target="_blank"
                rel="noopener noreferrer"
              >
                romain@pecorino.studio
              </a>
              <br />
            </span>
          </div>
          <div className="apropos-block">
            <h3 className="apropos-title">CONDITIONS GÉNÉRALES DE VENTE</h3>
            <h4 className="apropos-title">PRÉAMBULE</h4>
            <span className="apropos-text">
              Les présentes conditions générales de ventes régissent l’ensemble
              des contrats de vente des produits appartenant à Tranquillement
              SAS, dont le siège social est à La Croix 49170 Saint Germain des
              Prés inscrite au RCS de Angers sous le numéro 831 649 769 et ses
              clients.
              <br />
              <br />
              Le client reconnaît avoir pris connaissance des conditions
              générales de ventes ci après exposées. La passation d’une commande
              par internet entraîne de plein droit votre adhésion à nos
              conditions générales de vente.
              <br />
              <br />
              En cas de non-respect des présentes conditions par l’acheteur, la
              société Les Vendanges d’Antoine se réserve le droit d’annuler tout
              ou partie des commandes en cours ou de suspendre les livraisons de
              marchandises.
              <br />
              <br />
            </span>
            <h4 className="apropos-title">ARTICLE 1 : DROIT APPLICABLE</h4>
            <span className="apropos-text">
              La société ayant son siège social sur le territoire Français le
              droit applicable à tout contrat de vente est le droit français.
              <br />
              <br />
            </span>
            <h4 className="apropos-title">ARTICLE 2 : CHAMPS D'APPLICATION</h4>
            <span className="apropos-text">
              Les présentes conditions s’appliquent à la vente de produits Posey
              via le site www.posey.cool
              <br />
              <br />
              En conséquence, le fait de passer commande implique l’adhésion
              entière et sans réserve de l’acheteur à ces « conditions générales
              de vente », à l’exclusion de tous autres documents tels que
              prospectus, presses, catalogues ou e-mailing, émis par le vendeur
              qui n’ont qu’une valeur indicative.
              <br />
              <br />
              Toutes les clauses et conditions contraires aux présentes
              Conditions Générales de Vente figurant sur les bons de commande et
              plus généralement sur tout type de correspondance émanant de
              l’acheteur seront réputées non écrites et n’auront aucune valeur
              contractuelle entre les parties. Il ne peut être dérogé aux
              présentes Conditions Générales de Vente que par accord exprès et
              écrit de la société Les Vendanges d’Antoine.
              <br />
              <br />
              Les informations portées sur les catalogues, prospectus,
              publicités, notices ou autres documents de Posey ne sont données
              qu’à titre indicatif et n’engagent pas la société qui pourra les
              modifier à tout moment.
              <br />
              <br />
              Les photographies des produits ne sont données qu’à titre
              d’illustration, il est possible que le produit diffère de ce qui
              est affiché. Les informations apportées par les photographies
              n’engagent pas la société Les Vendanges d’Antoine qui pourra les
              modifier à tout moment.
              <br />
              <br />
              La société Tranquillement SAS se réserve le droit de corriger
              toute erreur matérielle accidentelle ou toute omission sur les
              présentes Conditions, ainsi que sur tout autre document
              contractuel.
              <br />
              <br />
            </span>
            <h4 className="apropos-title">Article 3 : Information légale</h4>
            <span className="apropos-text">
              L’article L 3342-1 du Code de la Santé Publique stipule que la
              vente d’alcool à des mineurs de moins de dix-huit (18) ans est
              interdite. L’Acheteur s’engage, en remplissant le bon de commande,
              à avoir dix-huit-ans révolus à la date de la commande.
              <br />
              <br />
              A cet effet le site posey.cool a fait l’objet d’une déclaration
              auprès de la Commission Nationale de l’Informatique et des
              Libertés.
              <br />
              <br />
              L’abus d’alcool est dangereux pour la santé. A consommer avec
              modération.
              <br />
              <br />
            </span>
            <h4 className="apropos-title">
              Article 4 : La boutique en ligne- commande
            </h4>
            <span className="apropos-text">
              Toute commande devra être effectuée par l’intermédiaire de la
              Boutique en Ligne : www.posey.cool
              <br />
              <br />
              L’offre commerciale de la société Les Vendanges d’Antoine porte
              sur l’ensemble de produits présentés au sein du catalogue de la
              Boutique en Ligne. La boutique en ligne mentionne les
              caractéristiques essentielles de chaque produit, son prix, le
              montant des frais de livraison.
              <br />
              <br />
              La commande est confirmée par la société Les Vendanges d’Antoine
              par l’envoi d’un mail à l’acheteur.
              <br />
              <br />
            </span>
            <h4 className="apropos-title">
              Article 5 : Validité de la commande
            </h4>
            <span className="apropos-text">
              La société Les Vendanges d’Antoine se réserve le droit de refuser
              toute commande pour des motifs légitimes et plus particulièrement
              dans le cas où les quantités commandées, au moyen d’une ou
              plusieurs commandes, sont anormalement élevées par rapport aux
              quantités habituellement commandées par les acheteurs en qualité
              de consommateurs.
              <br />
              <br />
            </span>
            <h4 className="apropos-title">
              {" "}
              Article 6 : Disponibilité des stocks :
            </h4>
            <span className="apropos-text">
              Les produits sont livrés dans la limite des stocks disponibles.
              <br />
              <br />
              En cas d’indisponibilité du produit commandé ou d’un retard dans
              la livraison, la société doit rembourser le client dans les 14
              jours suivant la demande de remboursement.
              <br />
              <br />
              Le client peut, s’il le souhaite, obtenir un produit équivalent en
              lieu et place du produit commandé.
              <br />
              <br />
            </span>
            <h4 className="apropos-title">Article 7 : Prix</h4>
            <span className="apropos-text">
              Les prix de nos produits s’entendent en euros, toutes taxes
              comprises, hors frais de port.
              <br />
              <br />
              L’acheteur est tenu par une obligation de paiement du prix au
              moment de la commande sur la Boutique en Ligne.
              <br />
              <br />
              Les prix facturés des produits sont ceux figurant sur la Boutique
              en Ligne à la date de la confirmation de la commande par
              l’acheteur.
              <br />
              <br />
              La société Tranquillement SAS se réserve le droit de modifier les
              tarifs en vigueur sur la Boutique en Ligne à tout moment. Le
              changement tarifaire sera automatiquement applicable à la date de
              la publication de la modification des prix sur la Boutique en
              Ligne.
              <br />
              <br />
              Toutes les commandes, quelle que soit leurs origines, sont
              payables exclusivement en Euros.
              <br />
              <br />
              Les frais de port, les taxes douanières et formalités sont à la
              charge exclusive du client.
              <br />
              <br />
            </span>
            <h4 className="apropos-title">Article 8 : Paiement</h4>
            <span className="apropos-text">
              Le règlement des produits s’effectue en totalité à la commande,
              soit :<br />
              <br />
              par virement sur notre compte bancaire aux références suivantes :
              ______________________. par Paypal. <br />
              <br />
              La société Les Vendanges d’Antoine se réserve le droit de modifier
              les moyens de paiement disponibles à tout moment.
              <br />
              <br />
              Pour toute commande supérieure à 500 € sur la France et 250 € sur
              l’Europe, il vous sera demandé de réaliser le paiement
              obligatoirement par virement bancaire avec information de la
              banque de l’effectivité du virement.
              <br />
              <br />
              Conformément aux dispositions de l’article L 441-6 du Code de
              Commerce, dans sa version en vigueur au 1er janvier 2013, toute
              somme non payée dans les trente jours suivant l’échéance est
              augmentée du taux de l’intérêt légal majoré de trois points. Les
              pénalités de retard sont exigibles sans qu’aucun rappel ne soit
              nécessaire. Une indemnité forfaitaire d’un montant de 40 €, telle
              que fixée par décret n° 2012-115 du 2 octobre 2012, sera due, pour
              frais de recouvrement en cas de retard de paiement.
              <br />
              <br />
            </span>
            <h4 className="apropos-title">
              Article 9 : Modalités de livraison et d’expédition
            </h4>
            <span className="apropos-text">
              Les produits commandés sont livrés à l’adresse indiquée par le
              client. Le colis sera acheminé jusqu’au destinataire par
              l’intermédiaire d’un prestataire de notre choix.
              <br />
              <br />
              Toute commande est traitée par le service Client internet de la
              société Les Vendanges d’Antoine et expédiée du lundi au vendredi
              inclus, sauf jours fériés.
              <br />
              <br />
              Les délais d’expédition sont en moyenne de 2 à 3 jours ouvrés. Il
              peut être allongé en période de forte activité ou en cas de
              fermeture exceptionnelle. Le délai de livraison sera au maximum de
              30 jours. Au-delà le client est en droit de réclamer le
              remboursement dans les 14 jours de la réclamation.
              <br />
              <br />
              Il est expressément convenu que l’acheteur devient responsable des
              produits dès la livraison, le transfert de possession valant
              transfert de risque.
              <br />
              <br />
            </span>
            <h4 className="apropos-title">Article 10 : Réserve de propriété</h4>
            <span className="apropos-text">
              La société Les Vendanges d’Antoine conserve la pleine et entière
              propriété des produits jusqu’au paiement intégral de leur prix, en
              principal et intérêts, par l’acheteur.
              <br />
              <br />
              Jusqu’à leur complet paiement, l’acheteur s’engage à ne pas
              revendre les produits impayés, à ne pas s’en déposséder et à
              prendre toutes dispositions pour que ces produits soient conservés
              dans l’état dans lequel ils ont été livrés et qu’ils restent
              individualisés comme étant la propriété de la société.
              <br />
              <br />
            </span>
            <h4 className="apropos-title">
              Article 11 : Circonstances libératoires
            </h4>
            <span className="apropos-text">
              La société Les Vendanges d’Antoine est libérée de ses obligations
              par la survenance d’évènements constitutifs de force majeure, cas
              fortuits ou de fait du prince.
              <br />
              <br />
              A ce titre, sont notamment comme cas de force majeure ou fortuits
              les évènements suivants :<br />
              <br />
              l’incendie, l’inondation ou tout autre accident ayant causé la
              destruction complète ou partielle des stocks et approvisionnements
              de la société Les Vendanges d’Antoine la grève, le lock-out sans
              qu’il soit besoin de rechercher par le fait de qui le mouvement a
              pris naissance la survenance d’une catastrophe naturelle, d’une
              guerre, d’un épisode de pandémie ;<br />
              <br />
              le manque de force motrice ou de matières premières résultant
              d’une cause d’ordre général, telle que notamment l’arrêt des
              transports, l’interruption de courant électrique, les bris de
              machine ;<br />
              <br />
              toute cause de rupture d’approvisionnement imputable aux
              fournisseurs de la société Les Vendanges d’Antoine.
              <br />
              <br />
              Le contrat de vente liant la société Les Vendanges d’Antoine à
              l’acheteur est alors suspendu de plein droit, sans indemnités, à
              compter de la date de survenance de l’évènement.
              <br />
              <br />
            </span>
            <h4 className="apropos-title">
              Article 12 : Droit de rétractation de l’acheteur
            </h4>
            <h5 className="apropos-title">Exercice du droit de rétractation</h5>
            <span className="apropos-text">
              L’acheteur dispose d’un délai de quatorze (14) jours suivant la
              réception de la commande pour exercer son droit de rétraction,
              sans avoir à justifier d’un motif.
              <br />
              <br />
              Pour exercer son droit de rétractation, l’acheteur doit notifier
              sa décision au moyen d’une déclaration claire et précise dénuée de
              toute ambiguïté.
              <br />
              <br />
              La déclaration de rétractation de l’acheteur devra comporter les
              mentions obligatoires suivantes : nom de l’acheteur, adresse
              géographique et le cas échéant numéro de téléphone, de télécopieur
              et adresse électronique. Dans le cas où la réception du produit a
              été effectuée par un tiers autre que l’acheteur, ce dernier devra
              indiquer dans la déclaration de rétractation les coordonnées de ce
              tiers.
              <br />
              <br />
              La déclaration peut être faite par tout moyen au siège de la
              société.
              <br />
              <br />
              A réception, la société Les Vendanges d’Antoine communiquera à
              l’acheteur, sans délai un accusé de réception de la rétractation
              sur un support durable.
              <br />
              <br />
              L’acheteur ne peut se prévaloir de son droit de rétractation dans
              l’hypothèse où les biens en cause ont été confectionnés selon les
              spécifications de l’acheteur ou nettement personnalisés ou qui, du
              fait de leur nature, ne peuvent être réexpédiés ou sont
              susceptibles de se détériorer ou de se périmer rapidement.
              <br />
              <br />
              Le droit de rétractation ne peut être exercé pour les produits qui
              auront été descellés ou ouverts par l’acheteur ou le destinataire
              de la commande après leur livraison.
              <br />
              <br />
            </span>
            <h5 className="apropos-title">Effet de la rétractation</h5>
            <span className="apropos-text">
              En cas de rétractation à l’initiative de l’acheteur, la société
              Les Vendanges d’Antoine remboursera l’acheteur de la totalité des
              sommes versées, au plus tard quatorze (14) jours à compter de la
              réception par elle de la décision de l’acheteur de se rétracter,
              conformément aux dispositions de l’article 12a) ci-dessus.
              <br />
              <br />
              L’acheteur s’engage à retourner les produits reçus sans délai, le
              cas échéant au plus tard quatorze (14) jours à compter de la
              notification de la déclaration à la société Les Vendanges
              d’Antoine. La rétractation ne sera effective qu’après renvoi des
              produits à la société Les Vendanges d’Antoine avant l’expiration
              du délai de quatorze (14) Jours.
              <br />
              <br />
              La commande doit être retournée par l’acheteur à l’adresse
              suivante :<br />
              <br />
              XXXXXXXX
              <br />
              <br />
              Les frais de renvoi des produits sont à la charge de l’acheteur.
              Ces frais varient avec le poids du colis, et sont estimés à un
              maximum d’environ 50 EUR en France Métropolitaine. La société ne
              saurait être tenue responsable de l’inexactitude de l’estimation
              des frais de port.
              <br />
              <br />
            </span>
            <h5 className="apropos-title">Responsabilité de l’acheteur</h5>
            <span className="apropos-text">
              La responsabilité de l’acheteur est engagée en cas de dépréciation
              du produit ou de son emballage.
              <br />
              <br />
            </span>
            <h4 className="apropos-title">
              Article 13 : Garantie des produits
            </h4>
            <span className="apropos-text">
              Les produits vendus correspondent matériellement aux
              spécifications présentées à l’acheteur par la Boutique en Ligne
              lorsqu’ils sont utilisés en stricte conformité aux fonctions pour
              lesquels ils ont été vendus.
              <br />
              <br />
              Sauf dispositions légales contraires, la responsabilité de la
              société Les Vendanges d’Antoine ne saurait en aucun cas excéder le
              montant de la valeur des marchandises facturées.
              <br />
              <br />
              En toute hypothèse, le client bénéficie de la garantie légale de
              conformité, de la garantie légale d’éviction et des vices cachés
              (article 1625 et suivants du Code Civil) à la condition que
              l’acheteur fasse la preuve du défaut caché, le vendeur doit
              légalement en réparer toutes les conséquences (article 1641 et
              suivant du Code Civil) ; si l’acheteur s’adresse aux Tribunaux, il
              doit le faire dans un « bref délai » à compter de la découverte du
              défaut caché.
              <br />
              <br />
              En conséquence, l’acheteur a jusqu’à 3 mois à partir de la date de
              livraison pour faire parvenir sa plainte à la société Les
              Vendanges d’Antoine. Au-delà de ce délai, nous ne seront plus en
              mesure de garantir le produit contesté puisque aucun contrôle sur
              la qualité du lieu de stockage ne peut être effectué, ou
              l’acheteur devra fournir la preuve que l’altération du produit est
              antérieur à la vente.
              <br />
              <br />
            </span>
            <h4 className="apropos-title">
              Article 14 : Données personnelles et charte de confidentialité
            </h4>
            <span className="apropos-text">
              Conformément à la loi « Informatique et Libertés » du 6 janvier
              1978 telle que modifiée par la loi du 6 août 2004, la collecte et
              le traitement des données personnelles de l’acheteur via le site
              www.posey.cool ont fait l’objet d’une déclaration auprès de la
              Commission Nationale de l’Informatique et des Libertés (numéro de
              déclaration 1797740).
              <br />
              <br />
              L’acheteur dispose d’un droit général d’accès, de modification, de
              rectification et de suppression de ses données personnelles.
              <br />
              <br />
              L’acheteur peut exercer ses droits en adressant un courrier à :
              <br />
              <br />
              XXXX
              <br />
              <br />
            </span>
            <h4 className="apropos-title">
              Article 15 : Signature électronique
            </h4>
            <span className="apropos-text">
              Le « OK » associé à la procédure d’authentification et de non
              répudiation et à la protection de l’intégralité des messages,
              constituent une signature électronique. Cette signature
              électronique a valeur, entre les parties, de signature manuscrite.
              <br />
              <br />
            </span>
            <h4 className="apropos-title">
              Article 16 : Absence de renonciation tacite
            </h4>
            <span className="apropos-text">
              Le fait par l’une des Parties, à quelque moment que ce soit, de ne
              pas exercer une prérogative qui lui est reconnue par les présentes
              Conditions Générales de Ventes, notamment de ne pas procéder à la
              résiliation du contrat de vente ou d’exercer cette prérogative
              avec retard, ou de ne pas exiger l’exécution d’une stipulation
              quelconque des Conditions Générales de Vente, ne pourra en aucun
              cas être interprété comme une renonciation expresse ou tacite de
              cette Partie, ni au droit d’exercer ladite prérogative à l’avenir,
              ni à son droit d’exiger l’exécution scrupuleuse des engagements
              souscrits par l’autre Partie d’entreprendre toute action ou
              réclamation dont elle pourrait disposer en relation avec
              l’inexécution de ses obligations par l’autre Partie, ni
              éventuellement à son droit de résilier le présent contrat pour
              toute violation de même nature ou d’une nature différente.
              <br />
              <br />
            </span>
            <h4 className="apropos-title">
              Article 17 : Droits de propriété intellectuelle
            </h4>
            <span className="apropos-text">
              La marque POSEY est déposée sous le numéro 16/4297566
              <br />
              <br />
              Toute reproduction, imitation sera poursuivie au titre de
              l’infraction de contrefaçon.
              <br />
              <br />
              Le site www.posey.cool et chacun des éléments qui le composent
              (tels que marques déposées, noms de domaine, textes,
              arborescences, logiciels animations, images, photographies,
              illustrations, emballages, schémas, logos, sons et musiques) sont
              la propriété exclusive de la société Les Vendanges d’Antoine ou
              des tiers qui ont dûment autorisé par elle à exploiter ces
              éléments, qui est seule habilitée à exploiter les droits de
              propriété intellectuelle afférents.
              <br />
              <br />
              Aucun élément du site ne peut-être utilisé, reproduit, diffusé ou
              publié entièrement ou partiellement sans autorisation écrite de la
              société Les Vendanges d’Antoine.
              <br />
              <br />
              L’usage de tout ou partie du site www.posey.cool, notamment par
              téléchargement, reproduction, transmission, représentation ou
              diffusion à d’autres fins que pour votre usage personnel et privé
              dans un but non commercial est strictement interdit.
              <br />
              <br />
            </span>
            <h4 className="apropos-title">
              Article 18 : Liens hypertexte et commentaires
            </h4>
            <span className="apropos-text">
              La création de liens hypertextes vers le site www.posey.cool est
              soumise à l’autorisation écrite et préalable de Les Vendanges
              d’Antoine, qui peut être révoquée à tout moment.
              <br />
              <br />
              Les Vendanges d’Antoine décline toute responsabilité (notamment
              éditoriale) concernant l’accès au site – et à leur contenu ayant
              un lien hypertexte vers ou sur le site www.posey.cool <br />
              <br />
              Les Vendanges d’Antoine se réserve le droit de supprimer tout
              commentaire publié par un internaute et/ou un acheteur sur le site
              www.posey.cool <br />
              <br />
            </span>
            <h4 className="apropos-title">
              Article 19 : Accès à la boutique en ligne
            </h4>
            <span className="apropos-text">
              Les Vendanges d’Antoine se réserve le droit de faire des
              modifications sur la Boutique en Ligne et sur le site
              www.posey.cool à tout moment et sans préavis.
              <br />
              <br />
              Les Vendanges d’Antoine décline toute responsabilité en cas
              d’interruption ou d’inaccessibilité du site www.posey.cool, de
              survenances de bogues, ou de tout dommage résultant d’actes
              frauduleux de la part de tiers (notamment par intrusion) à partir
              du site www.posey.cool <br />
              <br />
            </span>
            <h4 className="apropos-title">
              Article 20 : Droit applicable –juridictions compétentes
            </h4>
            <span className="apropos-text">
              Les présentes conditions générales sont exclusivement régies par
              la loi française, à l’exclusion de tout autre droit, issu
              notamment de la convention de Vienne du 11 avril 1980 sur la vente
              internationale de marchandises.
              <br />
              <br />
            </span>
            <h4 className="apropos-title">Article 21 : Contact</h4>
            <span className="apropos-text">
              Pour toute question, nous remercions les internautes et/ou
              acheteurs de bien vouloir envoyer un message à Les Vendanges
              d’Antoine en renseignant leur demande dans l’espace dédié de la
              rubrique « contact » accessible sur toutes les pages de la
              Boutique en Ligne.
              <br />
              <br />
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Mentionslegales;
