import "../css/Noustrouver.sass";

import Footer from "../components/Footer.js";
import Lieu from "../components/Lieu.js";
import Navbar from "../components/Navbar.js";
import React from "react";

function Noustrouver() {
  return (
    <div className="noustrouver-page">
      <Navbar currentPage="noustrouver" />
      <div>
        <header className="noustrouver-header">
          <h3 className="noustrouver-title">CAVISTES</h3>
          <div className="lieux-container">
            <Lieu
              nom="NYSA MONTORGUEIL"
              adresse="94 rue Montorgueil"
              codepostal="75002 Paris"
              pays="France"
              couleur="rouge"
              className="lieux-lieu"
            />
            <Lieu
              nom="NYSA BRETAGNE"
              adresse="33 rue de Bretagne"
              codepostal="75003 Paris"
              pays="France"
              couleur="bleu"
            />
            <Lieu
              nom="NYSA SAINT ANTOINE"
              adresse="95 rue Saint Antoine"
              codepostal="75004 Paris"
              pays="France"
              couleur="vert"
            />
            <Lieu
              nom="NYSA RAMBUTEAU"
              adresse="29 rue Rambuteau"
              codepostal="75004 Paris"
              pays="France"
              couleur="jaune"
            />
            <Lieu
              nom="NYSA MAYET"
              adresse="4 rue Mayet"
              codepostal="75006 Paris"
              pays="France"
              couleur="rouge"
            />
            <Lieu
              nom="NYSA CLER"
              adresse="30 rue Cler"
              codepostal="75007 Paris"
              pays="France"
              couleur="bleu"
            />
            <Lieu
              nom="NYSA MARTYRS"
              adresse="39 rue des Martyrs"
              codepostal="75009 Paris"
              pays="France"
              couleur="vert"
            />
            <Lieu
              nom="NYSA ROCHECHOUART"
              adresse="32 rue de Rochechouart"
              codepostal="75009 Paris"
              pays="France"
              couleur="jaune"
            />
            <Lieu
              nom="NYSA FAUBOURG MONTMARTRE"
              adresse="17 rue du Faubourg Montmartre"
              codepostal="75009 Paris"
              pays="France"
              couleur="rouge"
            />
            <Lieu
              nom="NYSA FAUBOURG SAINT DENIS"
              adresse="59 rue du Faubourg Saint Denis"
              codepostal="75010 Paris"
              pays="France"
              couleur="bleu"
            />
            <Lieu
              nom="NYSA FAUBOURG SAINT MARTIN"
              adresse="203 rue du Faubourg Saint Martin"
              codepostal="75010 Paris"
              pays="France"
              couleur="vert"
            />
            <Lieu
              nom="NYSA LANCRY"
              adresse="55 rue de Lancry"
              codepostal="75010 Paris"
              pays="France"
              couleur="jaune"
            />
            <Lieu
              nom="NYSA VOLTAIRE"
              adresse="26 rue Voltaire"
              codepostal="75011 Paris"
              pays="France"
              couleur="rouge"
            />
            <Lieu
              nom="NYSA ALIGRE"
              adresse="3 Place d’Aligre"
              codepostal="75012 Paris"
              pays="France"
              couleur="bleu"
            />
            <Lieu
              nom="NYSA RENDEZ-VOUS"
              adresse="24 rue du Rendez-Vous"
              codepostal="75012 Paris"
              pays="France"
              couleur="vert"
            />
            <Lieu
              nom="NYSA DAUMESNIL"
              adresse="254 avenue Daumesnil"
              codepostal="75012 Paris"
              pays="France"
              couleur="jaune"
            />
            <Lieu
              nom="NYSA PINEL"
              adresse="5 place Pinel"
              codepostal="75013 Paris"
              pays="France"
              couleur="rouge"
            />
            <Lieu
              nom="NYSA MOUTON-DUVERNET"
              adresse="24 rue Mouton-Duvernet"
              codepostal="75014 Paris"
              pays="France"
              couleur="bleu"
            />
            <Lieu
              nom="NYSA LECOURBE"
              adresse="108 rue Lecourbe"
              codepostal="75015 Paris"
              pays="France"
              couleur="vert"
            />
            <Lieu
              nom="NYSA SAINT CHARLES"
              adresse="112 rue St Charles"
              codepostal="75015 Paris"
              pays="France"
              couleur="jaune"
            />
            <Lieu
              nom="NYSA AVENUE DE VERSAILLES"
              adresse="205 avenue de Versailles"
              codepostal="75016 Paris"
              pays="France"
              couleur="rouge"
            />
            <Lieu
              nom="NYSA BATIGNOLLES"
              adresse="30 rue des Batignolles"
              codepostal="75017 Paris"
              pays="France"
              couleur="bleu"
            />
            <Lieu
              nom="NYSA LÉVIS"
              adresse="25 rue Lévis"
              codepostal="75017 Paris"
              pays="France"
              couleur="vert"
            />
            <Lieu
              nom="NYSA PONCELET"
              adresse="10 rue Poncelet"
              codepostal="75017 Paris"
              pays="France"
              couleur="jaune"
            />
            <Lieu
              nom="NYSA TOCQUEVILLE"
              adresse="76 rue de Tocqueville"
              codepostal="75017 Paris"
              pays="France"
              couleur="rouge"
            />
            <Lieu
              nom="NYSA LEPIC"
              adresse="18 rue Lepic"
              codepostal="75018 Paris"
              pays="France"
              couleur="bleu"
            />
            <Lieu
              nom="NYSA ORDENER"
              adresse="176 rue Ordener"
              codepostal="75018 Paris"
              pays="France"
              couleur="vert"
            />
            <Lieu
              nom="NYSA CAULAINCOURT"
              adresse="121 rue Caulaincourt"
              codepostal="75018 Paris"
              pays="France"
              couleur="jaune"
            />
            <Lieu
              nom="NYSA OLIVE"
              adresse="7 Rue de l’Olive"
              codepostal="75018 Paris"
              pays="France"
              couleur="rouge"
            />
            <Lieu
              nom="NYSA SECRÉTAN"
              adresse="17 avenue Sécrétan"
              codepostal="75017 Paris"
              pays="France"
              couleur="bleu"
            />
            <Lieu
              nom="NYSA BELLEVILLE"
              adresse="122 rue de Belleville"
              codepostal="75020 Paris"
              pays="France"
              couleur="vert"
            />
            <Lieu
              nom="NYSA CAPITAINE FERBER"
              adresse="3 rue du Capitaine Ferber"
              codepostal="75020 Paris"
              pays="France"
              couleur="jaune"
            />
            <Lieu
              nom="NYSA GAMBETTA"
              adresse="216 Bis rue des Pyrénées"
              codepostal="75020 Paris"
              pays="France"
              couleur="rouge"
            />
            <Lieu
              nom="NYSA PYRÉNÉES"
              adresse="104 rue des Pyrénées"
              codepostal="75020 Paris"
              pays="France"
              couleur="bleu"
            />
            <Lieu
              nom="NYSA VERSAILLES RUE AU PAIN"
              adresse="2 rue au Pain"
              codepostal="78000 Versailles"
              pays="France"
              couleur="vert"
            />
            <Lieu
              nom="NYSA POISSY"
              adresse="49 rue du Général de Gaulle"
              codepostal="78300 Poissy"
              pays="France"
              couleur="jaune"
            />
            <Lieu
              nom="NYSA CLICHY"
              adresse="72 rue de Neuilly"
              codepostal="92110 Clichy"
              pays="France"
              couleur="rouge"
            />
            <Lieu
              nom="NYSA MONTROUGE"
              adresse="61 avenue de la République"
              codepostal="92120 Montrouge"
              pays="France"
              couleur="bleu"
            />
            <Lieu
              nom="NYSA ISSY-LES-MOULINEAUX"
              adresse="14 rue du Général Leclerc"
              codepostal="92130 Issy-les-Moulineaux"
              pays="France"
              couleur="vert"
            />
            <Lieu
              nom="NYSA CLAMART"
              adresse="13 Place du Panorama"
              codepostal="92140 Clamart"
              pays="France"
              couleur="jaune"
            />
            <Lieu
              nom="NYSA SURESNES VAILLANT"
              adresse="12 avenue Edouard Vaillant"
              codepostal="92150 Suresnes"
              pays="France"
              couleur="rouge"
            />
            <Lieu
              nom="NYSA SURESNES-ZOLA"
              adresse="33 rue Emile Zola"
              codepostal="92150 Suresnes"
              pays="France"
              couleur="bleu"
            />
            <Lieu
              nom="NYSA MEUDON"
              adresse="32 rue de la République"
              codepostal="92190 Meudon"
              pays="France"
              couleur="vert"
            />
            <Lieu
              nom="NYSA GARENNE-COLOMBES"
              adresse="32 Qua4 bis rue Voltairei d'Austerlitz"
              codepostal="92250 La Garenne-Colombes"
              pays="France"
              couleur="jaune"
            />
            <Lieu
              nom="NYSA LEVALLOIS"
              adresse="32 rue Carnot"
              codepostal="92300 Levallois"
              pays="France"
              couleur="rouge"
            />
            <Lieu
              nom="NYSA CHÂTILLON"
              adresse="34 rue de la Mairie"
              codepostal="92320 Châtillon"
              pays="France"
              couleur="vert"
            />
            <Lieu
              nom="NYSA SCEAUX"
              adresse="86 rue Houdan"
              codepostal="92330 Sceaux"
              pays="France"
              couleur="jaune"
            />
            <Lieu
              nom="NYSA BOURG-LA-REINE"
              adresse="1 bis rue René Roeckel"
              codepostal="92340 Bourg-la-Reine"
              pays="France"
              couleur="rouge"
            />
            <Lieu
              nom="NYSA COURBEVOIE"
              adresse="10 avenue de la Liberté"
              codepostal="92400 Courbevoie"
              pays="France"
              couleur="rouge"
            />
            <Lieu
              nom="NYSA RUEIL-MALMAISON"
              adresse="8 Place de l’église"
              codepostal="92500 Rueil Malmaison"
              pays="France"
              couleur="bleu"
            />
            <Lieu
              nom="NYSA ASNIÈRES BOURGUIGNONS"
              adresse="66 rue des Bourguignons"
              codepostal="92600 Asnières-Sur-Seine"
              pays="France"
              couleur="vert"
            />
            <Lieu
              nom="NYSA ASNIÈRES STATION"
              adresse="10 rue de la Station"
              codepostal="92600 Asnières-sur-Seine"
              pays="France"
              couleur="jaune"
            />
            <Lieu
              nom="NYSA COLOMBES"
              adresse="63 Rue Saint Denis"
              codepostal="92700 Colombes"
              pays="France"
              couleur="rouge"
            />
            <Lieu
              nom="NYSA PUTEAUX"
              adresse="111 rue Jean Jaurès"
              codepostal="92800 Puteaux"
              pays="France"
              couleur="bleu"
            />
            <Lieu
              nom="NYSA LES LILAS"
              adresse="70 rue de Paris"
              codepostal="93260 Les Lilas"
              pays="France"
              couleur="vert"
            />
            <Lieu
              nom="NYSA VARENNE-SAINT-HILAIRE"
              adresse="92 avenue du Bac"
              codepostal="94210 La Varenne-Saint-Hilaire"
              pays="France"
              couleur="jaune"
            />
            <Lieu
              nom="NYSA ENGHIEN-LES-BAINS"
              adresse="54 rue du Général de Gaulle"
              codepostal="95880 Enghien-les-Bains"
              pays="France"
              couleur="rouge"
            />
            <Lieu
              nom="NYSA MASSÉNA"
              adresse="23 Cours Vitton"
              codepostal="69006 Lyon"
              pays="France"
              couleur="bleu"
            />
            <Lieu
              nom="NYSA MONPLAISIR"
              adresse="108 avenue des Frères Lumière"
              codepostal="69008 Lyon"
              pays="France"
              couleur="vert"
            />
            <Lieu
              nom="NYSA LONDRES"
              adresse="186 Fulham Rd, SW10 9PN"
              codepostal="SW10 9PN, Londres"
              pays="France"
              couleur="jaune"
            />
          </div>

          <h3 className="noustrouver-title">RESTAURANTS & BARS</h3>
          <div className="lieux-container">
            <Lieu
              nom="La Souris Verte"
              adresse="190 rue Marcadet"
              codepostal="75018 Paris"
              pays="France"
              couleur="rouge"
            />
            <Lieu
              nom="La Cité Fertile"
              adresse="14 avenue Edouard Vaillant"
              codepostal="93500 Pantin"
              pays="France"
              couleur="rouge"
            />
            <Lieu
              nom="Café Caché"
              adresse="104 Rue d'Aubervilliers"
              codepostal="75019 Paris"
              pays="France"
              couleur="bleu"
            />
            <Lieu
              nom="Ribote"
              adresse="25 Rue Moretz"
              codepostal="75011 Paris"
              pays="France"
              couleur="vert"
            />
            <Lieu
              nom="Vignes"
              adresse="76 Boulevard de la Villette"
              codepostal="75019 Paris"
              pays="France"
              couleur="rouge"
            />
            <Lieu
              nom="Wanderlust"
              adresse="32 Quai d'Austerlitz"
              codepostal="75012 Paris"
              pays="France"
              couleur="bleu"
            />
            <Lieu
              nom="Pavillon Puebla"
              adresse="Parc des Buttes Chaumont"
              codepostal="75019 Paris"
              pays="France"
              couleur="vert"
            />
            <Lieu
              nom="Le Perchoir"
              adresse="14 Rue Crespin du Gast"
              codepostal="75011 PARIS"
              pays="France"
              couleur="jaune"
            />
            <Lieu
              nom="Badaboum"
              adresse="2B Rue des Taillandiers"
              codepostal="75011 Paris"
              pays="France"
              couleur="rouge"
            />
            <Lieu
              nom="Maison Lautrec"
              adresse="63 Rue Jean-Baptiste Pigalle"
              codepostal="75009 Paris"
              pays="France"
              couleur="bleu"
            />
            <Lieu
              nom="La Boissonnière"
              adresse="17 Rue Poissonnière"
              codepostal="75002 Paris"
              pays="France"
              couleur="vert"
            />
            <Lieu
              nom="Le Wood"
              adresse="1 Place de Thorigny"
              codepostal="75003 Paris"
              pays="France"
              couleur="jaune"
            />
            <Lieu
              nom="Paname Art Café"
              adresse="14 Rue de la Fontaine au Roi"
              codepostal="75011 Paris"
              pays="France"
              couleur="rouge"
            />
            <Lieu
              nom="La Maison Sage"
              adresse="15 Boulevard Saint-Martin"
              codepostal="75003 Paris"
              pays="France"
              couleur="bleu"
            />
            <Lieu
              nom="Le Réfectoire"
              adresse="31 Rue du Château d'Eau"
              codepostal="75010 Paris"
              pays="France"
              couleur="vert"
            />
            <Lieu
              nom="Le Carlie"
              adresse="177 rue Saint Martin"
              codepostal="75003 Paris"
              pays="France"
              couleur="jaune"
            />
            <Lieu
              nom="La Cantinetta"
              adresse="24 Cours Julien"
              codepostal="13006 Marseille"
              pays="France"
              couleur="rouge"
            />
            <Lieu
              nom="Chez Otto"
              adresse="150 Rue Jean Mermoz"
              codepostal="13008 Marseille"
              pays="France"
              couleur="bleu"
            />
            <Lieu
              nom="Le Paparazzo"
              adresse="Pôle Nautique des Chalets"
              codepostal="11430 Gruissan"
              pays="France"
              couleur="vert"
            />
          </div>

          <h3 className="noustrouver-title">FESTIVALS</h3>
          <div className="lieux-container">
            <Lieu
              nom="Pete The Monkey 2019"
              adresse=""
              codepostal="76000 Saint-Aubin-sur-Mer"
              pays="France"
              couleur="bleu"
            />
            <Lieu
              nom="We Love Green Festival 2018"
              adresse=""
              codepostal="Paris"
              pays="France"
              couleur="vert"
            />
            <Lieu
              nom="Hello Birds 2018"
              adresse=""
              codepostal="76790 Étretat"
              pays="France"
              couleur="jaune"
            />
            <Lieu
              nom="Biarritz En Été 2018"
              adresse=""
              codepostal="64200 Biarritz"
              pays="France"
              couleur="rouge"
            />
            <Lieu
              nom="Cabourg mon Amour 2018"
              adresse=""
              codepostal="14390 Carbourg"
              pays="France"
              couleur="bleu"
            />
            <Lieu
              nom="Calvi on the Rocks 2017"
              adresse=""
              codepostal="20260 Calvi"
              pays="France"
              couleur="vert"
            />
          </div>
        </header>
      </div>
      <Footer />
    </div>
  );
}

export default Noustrouver;
