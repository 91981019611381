import "react-toastify/dist/ReactToastify.min.css";
import "rodal/lib/rodal.css";
import "../css/Apropos.sass";

import * as emailjs from "emailjs-com";

import { ToastContainer, toast } from "react-toastify";

import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import React from "react";
import Rodal from "rodal";

class Apropos extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      formSubmitted: false,
      newsletterPopupVisible: false,
    };
  }

  show() {
    this.setState({ newsletterPopupVisible: true });
  }

  hide() {
    this.setState({ newsletterPopupVisible: false });
  }

  //Notif envoi ou erreur
  notifyOK = () =>
    toast.success("Message envoyé !", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  notifyNOK = () =>
    toast.error(
      "On dirait qu'il y a eu un problème ! Tu peux nous contacter à poseypourtoujours@gmail.com",
      {
        position: toast.POSITION.BOTTOM_RIGHT,
      }
    );

  //Quand le bouton "envoi" du formulaire de contact est activé
  handleSubmit(event) {
    event.preventDefault();

    console.log(event.target.sujet.value);
    if (event.target.sujet.value === "Presse/partenariat") {
      emailjs
        .sendForm(
          "service_op9mn2j",
          "template_contact",
          "#formContact",
          "user_CdmLbLkZM8bPedFNSHNKp"
        )
        .then(
          (response) => {
            console.log("SUCCESS!", response.status, response.text);
            document.getElementById("formContact").reset();
            this.notifyOK();
          },
          (err) => {
            console.log("FAILED...", err);
            this.notifyNOK();
          }
        );

      this.setState({
        formSubmitted: true,
      });
    } else if (event.target.sujet.value === "Service client") {
      emailjs
        .sendForm(
          "service_op9mn2j",
          "template_sav",
          "#formContact",
          "user_CdmLbLkZM8bPedFNSHNKp"
        )
        .then(
          (response) => {
            console.log("SUCCESS!", response.status, response.text);
            document.getElementById("formContact").reset();
            this.notifyOK();
          },
          (err) => {
            console.log("FAILED...", err);
            this.notifyNOK();
          }
        );

      this.setState({
        formSubmitted: true,
      });
    }
  }
  render() {
    return (
      <div className="apropos-page">
        <ToastContainer />
        <Rodal
          visible={this.state.newsletterPopupVisible}
          onClose={this.hide.bind(this)}
          className="apropos-news-rodal"
        >
          <div>
            <iframe
              title="NewsletterPopup"
              src="https://ec174375.sibforms.com/serve/MUIEAJ_S7FUGzaVDisK-6IH4VwHdqMKRi4AWuDcMN9Ai7kfCWHMAx4dhatnxAAx8h27RrBo-mbpAX9LUItocgaQBlyuRv3dpMqK5SpJAnlC-f7YQGn8BD6DYMTYeugsvXB2fKKdbAom_ghXNyWT7PC5733pgIYD3TRegC0GfNp0Xjv6pDPsxTMT3E4tPL_f8sSn9eLWA63l1OQDm"
              frameBorder="0"
              scrolling="auto"
              allowfullscreen
              className="apropos-news-iframe"
            ></iframe>
          </div>
        </Rodal>
        <Navbar currentPage="apropos" />
        <div>
          <header className="App-header">
            <div className="apropos-banner">
              <div className="rosey-logo">POSEY</div>
            </div>
            <div className="apropos-content-container">
              <div className="apropos-block">
                <h2 className="apropos-title">POSEY C'EST...</h2>
                <span className="apropos-text">
                  Posey c’est des grands vins de vacances mis en bouteille
                  tranquillement. Et deux copains qui font ça en rigolant :
                  Etienne de Pain Surprises et Antoine des Vendanges d’Antoine.
                  <br />
                  <br />
                  C’est rigolo, c’est beau et c’est bon.
                </span>
              </div>
              <div className="apropos-block">
                <h2 className="apropos-title">NEWSLETTER</h2>
                <button
                  className="apropos-button"
                  onClick={this.show.bind(this)}
                >
                  inscription
                </button>
                <h2 className="apropos-title">CONTACTEZ-NOUS</h2>
                <span className="apropos-text">
                  <form onSubmit={this.handleSubmit} id="formContact">
                    <div className="line">
                      <div id="input">
                        <input
                          className="contact-input"
                          type="text"
                          name="name"
                          placeholder="Nom"
                          required
                        />
                        <br />
                        <input
                          className="contact-input"
                          type="email"
                          name="email"
                          placeholder="Email"
                          required
                        />
                      </div>
                      <select
                        className="contact-sujet"
                        name="sujet"
                        id="sujet"
                        required
                      >
                        <option value="">Sujet ?</option>
                        <option value="Presse/partenariat">
                          Presse/partenariat
                        </option>
                        <option value="Service client">Service client</option>
                      </select>
                      <br />
                      <textarea
                        className="contact-message"
                        name="message"
                        placeholder="Comment pouvons-nous aider ?"
                        required
                      />
                      <div id="send">
                        <input
                          className="apropos-button"
                          type="submit"
                          value="Envoyer"
                        />
                      </div>
                    </div>
                  </form>
                </span>
              </div>
            </div>
          </header>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Apropos;
