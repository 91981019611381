import "../css/Produit.sass";

import React, { Component } from "react";

class Produit extends Component {
  render() {
    return (
      <a
        className={
          // "produit-container " + this.props.isAllOff + " " + this.props.filter
          this.props.produit_comingSoon
            ? "produit-container comingsoon"
            : "produit-container"
        }
        href={"/produit/" + this.props.produit_url}
      >
        <div className="produit-image-container">
          {/* <div className="produit-bg" /> */}
          <img
            className="produit-image"
            src={this.props.produit_image}
            // src={require("../img-js/produits/" + this.props.produit_image)}
            alt=""
          />
        </div>
        <div className="produit-footer">
          <div className="produit-footer-titre">
            {this.props.produit_titre.slice(0, -1)}
            <span>
              {this.props.produit_titre.substr(
                this.props.produit_titre.length - 1
              )}
            </span>
          </div>
          <div className="produit-footer-line">
            {this.props.produit_details}
          </div>

          {/* <div className="produit-footer-bouton">
              <BoutonBuy produit_id={this.props.produit_id} />
            </div> */}
          <div className="produit-footer-line price">
            <span className={this.props.produit_comingSoon ? "comingsoon" : ""}>
              {this.props.produit_prix}
            </span>
          </div>
        </div>
      </a>
    );
  }
}

export default Produit;
