import "../css/PopupLivraison.sass";

import { BrowserView, MobileView } from "react-device-detect";

import React from "react";
import ReactPlayer from "react-player";
import Rodal from "rodal";
import video from "../img-js/livraison.mp4";

function PopupLivraison({ visible, hide }) {
  return (
    <div>
      {/* POPUP YOUTUBE MOBILE */}
      <MobileView>
        <Rodal
          visible={visible}
          onClose={() => hide()}
          className="pageproduit-rodal"
        >
          <div style={{ textAlign: "center", marginTop: "70px" }}>
            <ReactPlayer
              width="330px"
              height="330px"
              playing={visible}
              loop={false}
              url={video}
              config={{
                vimeo: {
                  playerOptions: { controls: false },
                },
              }}
            />
          </div>
        </Rodal>
      </MobileView>
      {/* POPUP YOUTUBE DESKTOP */}
      <BrowserView>
        <Rodal
          visible={visible}
          onClose={() => hide()}
          className="pageproduit-rodal"
        >
          <div style={{ textAlign: "center" }}>
            <ReactPlayer
              width="100%"
              height="550px"
              playing={visible}
              url={video}
              controls={false}
            />
          </div>
        </Rodal>
      </BrowserView>
    </div>
  );
}

export default PopupLivraison;
