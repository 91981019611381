import "../css/Drop.sass";

import React, { useContext, useEffect, useState } from "react";

import ContentfulContext from "../ContentfulContext.js";
import Countdown from "react-countdown";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";

function Drop(props) {
  const [dataDrop, setDataDrop] = useState();
  const client = useContext(ContentfulContext);

  useEffect(() => {
    //LOAD DROP DATA
    client
      .getEntry("3rIM23bL9gAMeqlbE4eXvt")
      .then((entry) => {
        setDataDrop(entry.fields);
      })
      .catch(console.error);
  }, [client]);

  console.log("DROP QUERY : ", dataDrop);

  return (
    <div className="drop-page">
      <Navbar currentPage="drop" />
      <div>
        <header className="drop-header">
          <h3 className="drop-title">{dataDrop?.ligne1}</h3>
          <br />
          <h2 className="drop-title">
            {dataDrop && <Countdown date={dataDrop?.prochainDrop} />}
          </h2>
          <h3>{dataDrop?.ligne2}</h3>
        </header>
      </div>
      <Footer />
    </div>
  );
}

export default Drop;
