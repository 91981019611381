import "../css/Navbar.sass";

import { FaFacebookF, FaInstagram, FaMusic, FaStar } from "react-icons/fa";
import { HiMenu, HiOutlineX } from "react-icons/hi";
import React, { useContext, useEffect, useState } from "react";

import DropContext from "../DropContext.js";
import { Link } from "react-router-dom";
import RadioContext from "../RadioContext.js";
import onClickOutside from "react-onclickoutside";

function Navbar({ currentPage, scroll, enableShop, disableShop }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [active_page, setActive_page] = useState("");
  const [navHeight, setNavHeight] = useState("220px");
  Navbar.handleClickOutside = () => setMenuOpen(false);

  useEffect(() => {
    setActive_page(currentPage + "_selected");
  }, [currentPage]);

  const drop = useContext(DropContext);
  const radio = useContext(RadioContext);

  useEffect(() => {
    let height = "260px";
    if (drop || radio) {
      height = "320px";
    }
    if (drop && radio) {
      height = "360px";
    }
    setNavHeight(height);
  }, [drop, radio]);

  return (
    <div className={currentPage ? "navbar-sticky" : "navbar-relative"}>
      <div
        style={{
          height: menuOpen ? navHeight : "70px",
          maxHeight: menuOpen ? navHeight : "",
        }}
        className={menuOpen ? "navbar menuOpened" : "navbar"}
      >
        {/* HOME LOGO ---------*/}

        <button
          onClick={() => setMenuOpen(!menuOpen)}
          className={
            menuOpen
              ? "navbar-burger-menu-button menuOpened"
              : "navbar-burger-menu-button"
          }
        >
          {!menuOpen && <HiMenu />}
          {menuOpen && <HiOutlineX />}
        </button>

        <Link to="/" className="navbar-logo-container">
          {(currentPage === "home" || currentPage === "boutique") && (
            <div
              onClick={() => {
                disableShop();
              }}
              className="navbar-logo"
            >
              POSEY
            </div>
          )}
          {currentPage !== "home" && currentPage !== "boutique" && (
            <div className="navbar-logo">POSEY</div>
          )}
        </Link>

        {/* MENU ---------*/}
        <span className="navbar-menu-container">
          {(currentPage === "home" || currentPage === "boutique") && (
            <span className="navbar-menu-container">
              <h3
                className={"navbar-menu boutique " + active_page}
                onClick={() => {
                  enableShop();
                  scroll();
                  setMenuOpen(false);
                }}
              >
                Shop
              </h3>
            </span>
          )}
          {currentPage !== "home" && currentPage !== "boutique" && (
            <Link to="/?shop=1" className="navbar-menu-container">
              <h3 className={"navbar-menu boutique " + active_page}>Shop</h3>
            </Link>
          )}
        </span>
        {drop && (
          <Link to="/drop" className="navbar-menu-container">
            <h3 className={"navbar-menu drop " + active_page}>Drop</h3>
          </Link>
        )}

        {radio && (
          <Link to="/posey-radio" className="navbar-menu-container">
            <h3 className={"navbar-menu poseyradio " + active_page}>
              Posey Radio
            </h3>
          </Link>
        )}
        <Link to="/nous-trouver" className="navbar-menu-container">
          <h3 className={"navbar-menu noustrouver " + active_page}>
            Nous trouver
          </h3>
        </Link>
        <Link to="/a-propos" className="navbar-menu-container">
          <h3 className={"navbar-menu apropos " + active_page}>À propos</h3>
        </Link>

        {/* SOCIAL ICONS ---------*/}
        <div className="navbar-social-icons-container">
          <a
            href="https://www.facebook.com/poseypapi/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="navbar-social-icon">
              <FaFacebookF />
            </div>
          </a>
          <a
            href="https://www.instagram.com/leroseposey/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="navbar-social-icon">
              <FaInstagram />
            </div>
          </a>
          <a
            href="https://www.painsurprises.fr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="navbar-social-icon">
              <FaStar />
            </div>
          </a>

          <a
            href="https://soundcloud.com/poseypapi"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="navbar-social-icon">
              <FaMusic />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => Navbar.handleClickOutside,
};

export default onClickOutside(Navbar, clickOutsideConfig);
